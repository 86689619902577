<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { boolean, object, string } from 'yup'
const { open, initialPage } = defineProps<{
  /**
   * Whether the modal is open.
   */
  open?: boolean
  /**
   * Inital page
   */
  initialPage?: Page
}>()
const emit = defineEmits(['close'])
const { t: $t } = useI18n({ useScope: 'global' })
const { t } = useI18n({ useScope: 'local' })

const schema = object({
  title: string().required().label($t('cms.page.title')),
  slug: string().label($t('cms.page.slug')).defined(),
  showTitleOnPage: boolean()
    .nonNullable()
    .defined()
    .default(true)
    .label($t('cms.page.showTitleOnPage')),
  isPublished: boolean().defined().label($t('cms.page.isPublished')),
  layout: string()
    .required()
    .oneOf([PageLayout.Default, PageLayout.SideColumn])
    .label($t('cms.page.pageLayout')),
})

const { handleSubmit, isSubmitting, resetForm, setFieldError, errorBag }
  = useForm({
    validationSchema: toTypedSchema(schema),
    initialValues: initialPage || {
      title: '',
      slug: '',
      showTitleOnPage: true,
      isPublished: false,
      layout: PageLayout.Default,
    },
  })

const { handleServerError } = useServerErrorHandler(schema, setFieldError)
const { handleValidationError } = useFormValidationErrorHandler()

const submit = handleSubmit(async (values) => {
  try {
    if (!initialPage) {
      await createPage({
        ...values,
      })
    }
    else {
      await updatePage(initialPage.id, {
        ...values,
      })

      // If the slug has been changed and we're on a path that includes the slug,
      // replace the slug and navigate to that page.
      const currentRoute = useRoute().fullPath
      if (
        initialPage.slug !== values.slug
        && currentRoute.includes(initialPage.slug)
      ) {
        navigateTo(currentRoute.replace(initialPage.slug, values.slug || ''))
        return
      }
    }

    emit('close')
    resetForm()
  }
  catch (error) {
    console.log(`Form error`, error)
    handleServerError(error)
  }
}, handleValidationError)

const pageLayoutItems = Object.values(PageLayout)
// .map((pageLayout) => ({
//   value: pageLayout,
//   label: pageLayout,
// }))
</script>

<template>
  <BaseModal :open="open" @close="$emit('close')">
    <template #title>
      {{
        initialPage ? t('editHeadingTitle') : t('headingTitle')
      }}
    </template>
    <BaseParagraph>
      {{
        initialPage ? t('editHeadingText') : t('headingText')
      }}
    </BaseParagraph>

    <FormBase>
      <FormField name="title" :label="$t('cms.page.title')" />
      <FormField name="slug" :label="$t('cms.page.slug')" />

      <Field v-slot="{ field, errorMessage }" name="layout">
        
        <BaseListbox
          :items="pageLayoutItems"
          :label="$t('cms.page.pageLayout')"
          :error="errorMessage"
          v-bind="field"
        />
        {{ errorMessage }}
      </Field>

      <Field v-slot="{ field, errorMessage }" name="showTitleOnPage">
        <BaseCheckbox
          :label="$t('cms.page.showTitleOnPage')"
          :error="errorMessage"
          v-bind="field"
        />
        {{ errorMessage }}
      </Field>

      <Field v-slot="{ field, errorMessage }" name="isPublished">
        <BaseCheckbox
          :label="$t('cms.page.isPublished')"
          :error="errorMessage"
          v-bind="field"
        />
        {{ errorMessage }}
      </Field>
    </FormBase>

    <template #buttons>
      <BaseButton @click="$emit('close')">
        {{ $t('cancel') }}
      </BaseButton>

      <SubmitButton :is-submitting="isSubmitting" @click="submit">
        {{
          $t('save')
        }}
      </SubmitButton>
    </template>
  </BaseModal>
</template>

<i18n lang="json">
{
  "en": {
    "headingTitle": "Add a page",
    "headingText": "Add a new page",
    "editHeadingTitle": "Edit page",
    "editHeadingText": "Edit the settings of this page."
  },
  "nl": {
    "headingTitle": "Pagina toevoegen",
    "headingText": "Voeg een nieuwe pagina toe.",
    "editHeadingTitle": "Pagina bewerken",
    "editHeadingText": "Bewerk de instellingen van deze pagina."
  }
}
</i18n>
